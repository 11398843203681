































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import invetarioModule from "@/store/modules/invetario-module";
import { invetario } from "@/shared/dtos/invetario";
import { RouterNames } from "@/router/routernames";
import { API } from "@/shared/api";
import { document_fileDto } from "@/shared/dtos/document_fileDto";
import { UtilsString } from "@/utils/utils-string";
import { UtilsDate } from "@/utils/utils-date";
@Component({
  components: {
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue"),
    HTMLEditor: () => import("@/components/html-editor/html-editor.vue"),
    DxDateBox: () => import("devextreme-vue/date-box"),
    DocumentsHandler: () =>
      import("@/views/proyectos/Documentos/DocumentsHandler.vue"),
  },
  $_veeValidate: { validator: "new" },
})
export default class ficha_inventario extends Vue {
  show_qr: boolean = false;
  created() {
    if (this.is_new()) {
      invetarioModule.onGetinvetario(new invetario());
      this.datasource.id = 0;
      try {
        this.datasource.id_categroria = Number.parseInt(
          this.$route.query.id_categoria.toString()
        );
      } catch (error) {}

      this.datasource.stock = 1;
      if (this.is_sub_inventario()) {
        this.datasource.id_sub_inventario = this.id_sub_inventario();
        this.datasource.fechainicio_soporte = new Date();
        this.datasource.fechafin_soporte = UtilsDate.AddAnos(new Date(), 2);
      }
    } else {
      invetarioModule.getinvetario(this.$route.params.id).then(() => {
        this.get_files();
      });
    }
  }

  public get datasource() {
    return invetarioModule.invetario;
  }

  public get files() {
    return invetarioModule.files;
  }

  public get anos() {
    let numbers: number[] = [];
    for (let year = new Date().getFullYear(); year >= 1971; year--) {
      numbers.push(year);
    }
    return numbers;
  }

  GetUrlMovile() {
    return (
      document.location.origin +
      document.location.pathname +
      "#/main/inventario/readqr/" +
      this.$route.params.id
    );
  }

  ver_qr() {
    this.show_qr = true;
  }

  public is_new() {
    return Number.parseInt(this.$route.params.id) === -1;
  }

  public is_sub_inventario() {
    return this.id_sub_inventario() > -1;
  }

  public id_sub_inventario() {
    if (UtilsString.IsValidParamUrl(this.$route.query.id_sub_inventario)) {
      return Number.parseInt(
        UtilsString.ValueOf(this.$route.query.id_sub_inventario)
      );
    }
    return -1;
  }

  public cancelar() {
    this.$router.push({ name: RouterNames.inventario_treeview });
  }

  public eliminar() {
    invetarioModule
      .eliminarinvetario(this.datasource)
      .then(() => this.cancelar());
  }

  public aceptar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        if (this.is_new()) {
          invetarioModule
            .guardarinvetario(this.datasource)
            .then(() => this.cancelar());
        } else {
          invetarioModule
            .modificarinvetario(this.datasource)
            .then(() => this.cancelar());
        }
      }
    });
  }
  public get_files() {
    if (this.datasource.id === null) {
      return;
    }
    return invetarioModule.getfiles(this.datasource.id).catch(() => []);
  }

  public Geturl() {
    return API.invetario + "/documents/" + this.datasource.id;
  }

  public delete_file(file: document_fileDto): void {
    invetarioModule
      .eliminar_documento({
        object: this.datasource,
        doc: file,
      })
      .then(() => {
        this.get_files();
      });
  }

  public downlod_file(file: document_fileDto) {
    window.open(
      API.webApiBaseinventario + this.datasource.id + "/" + file.name
    );
  }
}
